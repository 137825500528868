import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import ProjectGrid from "../components/ProjectGrid"
import SEO from "../components/seo"

const Wrapper = styled.div`
  margin-top: 2rem;
`

const ProjectIndex = ({ data }) => {
  const projects = data.allMarkdownRemark.edges

  return (
    <Layout>
      <SEO title="All projects" />
      <Wrapper>
        <ProjectGrid projects={projects} />
      </Wrapper>
    </Layout>
  )
}

export default ProjectIndex

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { slug: { regex: "/^/projects/" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "Do MMMM, YYYY", locale: "en-GB")
            title
            description
            externalUrl
            color
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FIXED, height: 200)
              }
            }
          }
        }
      }
    }
  }
`
